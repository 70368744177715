(function () {
    'use strict';

    angular.module("mfgApp")
        .config(mfgAppRoutesConfig);

    mfgAppRoutesConfig.$inject = ['$stateProvider', '$urlRouterProvider', 'routesConstant'];

    function mfgAppRoutesConfig($stateProvider, $urlRouterProvider, routesConstant) {

        var MFG = routesConstant.MFG;

        var makeResolve = function () {
            return {
                allPermissions: ['CommonService', function (CommonService) {
                    var promise = CommonService.getAllPermissions()
                        .then(function (types) {
                            return loadPermissions(types)
                        });
                    angular.module('rolesConfig').value("allPermissions", promise);
                    return promise;
                }],
                allRoles: ['CommonService', function (CommonService) {
                    var promise = CommonService.getAllRoles()
                        .then(function (types) {
                            return loadRoles(types)
                        });
                    angular.module('rolesConfig').value("allRoles", promise);
                    return promise;
                }]
            }
        };

        $stateProvider
            .state(MFG.MAIN.stateName, {
                url: MFG.MAIN.url,
                templateUrl: '/static/templates/dashboard/MfgDashboard.html',
                controller: 'mfgMainController'
            })
            .state(MFG.DASHBOARD.stateName, {
                url: MFG.DASHBOARD.url,
                views: {
                    "content": {
                        controller: 'MfgDashboardCtrl',
                        templateUrl: '/static/templates/mfg/dashboard.html'
                    }
                },
                resolve: makeResolve()
            })
            .state(MFG.LICENSING.stateName, {
                url: MFG.LICENSING.url,
                views: {
                    "content": {
                        controller: 'LicensingCtrl',
                        templateUrl: '/static/templates/mfg/generateLicense.html'
                    }
                },
                resolve: makeResolve()
            })
            .state(MFG.ROGUE.stateName, {
                url: MFG.ROGUE.url,
                views: {
                    "content": {
                        controller: 'RogueCtrl',
                        templateUrl: '/static/templates/mfg/rogueSerialNumber.html'
                    }
                },
                resolve: makeResolve()
            })
            .state(MFG.MANAGELICENSE.stateName, {
                url: MFG.MANAGELICENSE.url,
                views: {
                    "content": {
                        controller: 'ManageLicensesCtrl',
                        templateUrl: '/static/templates/mfg/manageLicenses.html'
                    }
                },
                resolve: makeResolve()
            });

    }
})();
